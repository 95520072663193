<template>
    <div v-if="screen" class="align-center screen-qcm">
        <v-form ref="qcmForm" v-model="valid" lazy-validation>
            <div class="mb-2 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("qcmTitleLabel") }}</p>
                <v-text-field
                    :rules="[rules.notEmpty]"
                    :value="getValue('TITLE')"
                    class="custom-input rounded-lg font-weight-bold text-body-2"
                    dense
                    filled
                    required
                    type="text"
                    @change="setValue('TITLE', $event)"></v-text-field>
            </div>

            <div class="mb-2 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("qcmContentLabel") }}</p>
                <v-textarea
                    :rules="[rules.notEmpty]"
                    :value="getValue('DESCRIPTION')"
                    class="custom-input rounded-lg font-weight-bold text-body-2"
                    dense
                    filled
                    required
                    type="text"
                    @change="setValue('DESCRIPTION', $event)"></v-textarea>
            </div>

            <div class="mb-2 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("qcmImgUrlLabel") }}</p>
                <v-avatar class="my-3 align-self-center" color="lightGrey" size="150">
                    <template v-if="getValue('LOGO')">
                        <v-img :src="getValue('LOGO')" contain max-height="120" max-width="120"></v-img>
                    </template>
                    <template v-else>
                        <v-icon x-large>mdi-image-area</v-icon>
                    </template>
                </v-avatar>
                <div class="d-flex align-center">
                    <v-text-field
                        :rules="[rules.notEmpty]"
                        :value="getValue('LOGO')"
                        class="custom-input rounded-lg font-weight-bold text-body-2"
                        dense
                        filled
                        required
                        type="url"
                        @change="setValue('LOGO', $event)"></v-text-field>
                </div>
            </div>

            <div class="mb-2 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("qcmAnswers") }}</p>
                <div v-for="(answer, index) in tempAnswers" :key="answer.id">
                    <v-row>
                        <v-col cols="11">
                            <v-text-field
                                :rules="[rules.notEmpty]"
                                :value="answer.value"
                                class="custom-input rounded-lg font-weight-bold text-body-2"
                                dense
                                filled
                                required
                                type="text"
                                @change="setAnswer(index, $event)"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="1" class="d-flex">
                            <v-btn fab dark x-small color="error" @click="removeAnswer(index)" v-if="tempAnswers.length > 1"><v-icon small>mdi-delete</v-icon></v-btn>
                            <v-btn fab dark x-small color="primary" class="ms-2" v-if="index === tempAnswers.length-1" @click="addAnswer"><v-icon small>mdi-plus</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <div class="mb-2 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("qcmMaxAnswers") }}</p>
                <v-select
                    :items="nbMaxToSelect"
                    :rules="[rules.notEmpty]"
                    :value="nbMaxToSelectDefined"
                    class="custom-input rounded-lg font-weight-bold text-body-2"
                    dense
                    filled
                    required
                    @change="setValue('MAX_ANSWERS', $event)"
                ></v-select>
            </div>

            <div class="d-flex align-center">
                <div class="d-flex flex-column align-center ">
                    <div class="mb-5 d-flex flex-column input-group full-width">
                        <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("qcmSendBtnAction") }}</p>
                        <v-select
                            :items="screens"
                            :rules="[rules.notEmpty]"
                            :value="getAction('SEND_QCM_BUTTON')"
                            class="custom-input rounded-lg font-weight-bold text-body-2"
                            dense
                            filled
                            item-text="name"
                            item-value="id"
                            required
                            @change="setAction('SEND_QCM_BUTTON', $event)"
                        ></v-select>
                    </div>
                    <div class="mb-2 d-flex flex-column input-group full-width">
                        <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("qcmSendBtnLabel") }}</p>
                        <v-text-field
                            :disabled="isNull(getAction('SEND_QCM_BUTTON'))"
                            :placeholder="$t('qcmSendBtnPlaceHolder')"
                            :rules="[rules.notEmpty]"
                            :value="getValue('SEND_QCM_BUTTON')"
                            class="custom-input rounded-lg font-weight-bold text-body-2"
                            dense
                            filled
                            required
                            type="text"
                            @change="setValue('SEND_QCM_BUTTON', $event)"></v-text-field>
                    </div>
                </div>

                <v-divider class="my-8 mx-4" vertical></v-divider>

                <div class="my-5 d-flex flex-column align-center">
                    <div class="mb-5 d-flex flex-column input-group full-width">
                        <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("qcmCancelBtnAction") }}</p>
                        <v-select
                            :items="screens"
                            :rules="[rules.notEmpty]"
                            :value="getAction('CANCEL_QCM_BUTTON')"
                            class="custom-input rounded-lg font-weight-bold text-body-2"
                            dense
                            filled
                            item-text="name"
                            item-value="id"
                            required
                            @change="setAction('CANCEL_QCM_BUTTON', $event)"
                        ></v-select>
                    </div>
                    <div class="mb-2 d-flex flex-column input-group full-width">
                        <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("qcmCancelBtnLabel") }}</p>
                        <v-text-field
                            :disabled="isNull(getAction('CANCEL_QCM_BUTTON'))"
                            :placeholder="$t('qcmCancelBtnPlaceHolder')"
                            :rules="[rules.notEmpty]"
                            :value="getValue('CANCEL_QCM_BUTTON')"
                            class="custom-input rounded-lg font-weight-bold text-body-2"
                            dense
                            filled
                            required
                            type="text"
                            @change="setValue('CANCEL_QCM_BUTTON', $event)"></v-text-field>
                    </div>
                </div>
            </div>
        </v-form>

        <div class="d-flex align-center full-width">
            <CustomButton outlined @click="showTerminalModalPreviewDialog = true">{{ $t('previewBtn') }}</CustomButton>
            <v-spacer></v-spacer>
            <CustomButton @click="save">{{ $t("saveBtn") }}</CustomButton>
        </div>
        <TerminalModalPreview v-if="showTerminalModalPreviewDialog" :availableScreens="availableScreens" :currentScreen="currentScreen" :screens="screens" @hide="showTerminalModalPreviewDialog = false"/>
    </div>
</template>

<script>
import TerminalModalPreview from "@/components/SmartEngage/TerminalModalPreview";

export default {
    name: "QCM",
    props: ['screen', 'availableScreens'],
    components: {TerminalModalPreview},
    data: (vm) => {
        return {
            showTerminalModalPreviewDialog: false,
            currentScreen: {
                id: -1,
                name: null,
                templateType: null,
                inputs: [],
                launchScreen: false,
            },
            valid: true,
            rules: {
                notEmpty: value => vm.isNotNull(value) ? true : vm.$t('emptyFieldError')
            },
            tempAnswers: [
                {
                    id: 1,
                    value: null
                }
            ]
        }
    },
    computed: {
        screens() {
            let screens = [{id: -2, name: this.$t("noAction")}, ...this.availableScreens]
            return screens.filter(screen => screen.id !== this.currentScreen.id)
        },
        nbMaxToSelect(){
            let array = []
            for(let i = 1; i < this.tempAnswers.length + 1; i++){
                array.push(i)
            }
            return array
        },
        nbMaxToSelectDefined(){
            if(this.getValue("MAX_ANSWERS") && parseInt(this.getValue("MAX_ANSWERS")) <= this.tempAnswers.length){
                return parseInt(this.getValue("MAX_ANSWERS"))
            }else{
                return null
            }
        }
    },
    created() {
        if (this.isNotNull(this.screen)) {
            this.currentScreen = JSON.parse(JSON.stringify(this.screen))
        }

        if(this.getValue("ANSWERS")){
            this.tempAnswers = JSON.parse(this.getValue("ANSWERS"))
        }
    },
    methods: {
        save() {
            if (this.$refs.qcmForm.validate()) {
                this.$emit('screenUpdated2', this.currentScreen)
            }
        },
        getValue(name) {
            let index = this.currentScreen.inputs.findIndex(input => input.name === name)
            if (index !== -1) {
                return this.currentScreen.inputs[index].value
            }
            return null
        },
        getAction(name) {
            let index = this.currentScreen.inputs.findIndex(input => input.name === name)
            if (index !== -1) {
                if (this.isNotNull(this.currentScreen.inputs[index].action)) {
                    return parseInt(this.currentScreen.inputs[index].action)
                }
                return -2 //No Action
            }
            return -1 //Non défini
        },
        setValue(name, value) {
            let index = this.currentScreen.inputs.findIndex(input => input.name === name)
            if (index !== -1) {
                this.currentScreen.inputs[index].value = value
            } else {
                switch (name) {
                    case "TITLE":
                        this.currentScreen.inputs.push({
                            name: "TITLE",
                            value: value,
                            type: "TEXT",
                        });
                        break;
                    case "DESCRIPTION":
                        this.currentScreen.inputs.push({
                            name: "DESCRIPTION",
                            value: value,
                            type: "TEXT",
                        });
                        break;
                    case "LOGO":
                        this.currentScreen.inputs.push({
                            name: "LOGO",
                            value: value,
                            type: "IMAGE",
                        });
                        break;
                    case "MAX_ANSWERS":
                        this.currentScreen.inputs.push({
                            name: "MAX_ANSWERS",
                            value: value,
                            type: "TEXT"
                        });
                        break;
                    case "ANSWERS":
                        this.currentScreen.inputs.push({
                            name: "ANSWERS",
                            value: value,
                            type: "TEXT"
                        });
                        break;
                    case "SEND_QCM_BUTTON":
                        this.currentScreen.inputs.push({
                            name: "SEND_QCM_BUTTON",
                            value: value,
                            type: "BUTTON"
                        });
                        break;
                    case "CANCEL_QCM_BUTTON":
                        this.currentScreen.inputs.push({
                            name: "CANCEL_QCM_BUTTON",
                            value: value,
                            type: "BUTTON"
                        });
                        break;
                }
            }
        },
        setAction(name, actionScreenId) {
            let index = this.currentScreen.inputs.findIndex(input => input.name === name)
            if (index === -1) {
                this.currentScreen.inputs.push({
                    name: name,
                    value: "",
                    type: "BUTTON"
                })
                index = this.currentScreen.inputs.length - 1
            }

            if (actionScreenId >= 0) {
                this.currentScreen.inputs[index].action = actionScreenId
            } else {
                delete this.currentScreen.inputs[index].action
            }
        },
        setAnswer(index, value){
            this.tempAnswers[index].value = value
            this.setValue("ANSWERS", JSON.stringify(this.tempAnswers))
        },
        addAnswer(){
            this.tempAnswers.push({
                id: this.tempAnswers[this.tempAnswers.length - 1].id + 1,
                value: null
            })
        },
        removeAnswer(index){
            this.tempAnswers.splice(index, 1)
            this.setValue("ANSWERS", JSON.stringify(this.tempAnswers))
        }
    }
}
</script>

<style scoped>
.screen-qcm {
    min-width: 400px;
}

.custom-input >>> .v-input__slot {
    margin: 0 !important;
}

.custom-input >>> .v-input__slot::before {
    border: none !important;
}

.custom-input >>> .v-input__slot::after {
    border: none !important;
}

.custom-input >>> .v-input__slot {
    border: none !important;
}
</style>
