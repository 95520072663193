<template>
    <div class="d-flex flex-column screens" v-if="isNotNull(scenarioId)">
        <div class="d-flex flex-column manage-screens-header">
            <p class="d-flex align-center ma-0 text-h4 primary--text font-weight-bold">
                <a @click="$router.push({name: 'Scenarios'})">{{$t("screensHeaderScénariosTitle")}}</a>
                <template v-if="scenario">
                    <span class="ma-0 mx-3 font-weight-bold">></span>
                    <span class="ma-0 text-h6 primary--text font-weight-light">{{scenario.name}}</span>
                </template>
                <span class="mx-3 font-weight-light">></span>
                <a class="ma-0 text-h6 primary--text font-weight-light" @click="screenToManage = null">{{$t("screensHeaderScreensTitle")}}</a>
                <template v-if="screenToManage">
                    <span class="mx-3 font-weight-light">></span>
                    <span class="ma-0 text-h6 primary--text font-weight-light">{{$t("screensHeaderUpdateScreenTitle")}}</span>
                </template>
            </p>
        </div>
        <v-row class="mt-6">
            <v-col cols="9" class="pa-8">
                <div class="pa-5 d-flex flex-column rounded-xl full-height align-center justify-center screen-creation-wrapper">
                    <p v-if="isNull(screenToManage) || isNewScreen" class="justify-center ma-0 text-center text-h5 primary--text font-weight-bold">{{$t("manageScreensTitle")}}</p>
                    <!-- titre -->
                    <div v-else class="justify-center ma-0 text-center d-flex">
                        <div>
                            <div v-if="editName" class="mb-5 d-flex flex-column input-group">
                                <v-text-field class="custom-input rounded-lg font-weight-bold text-body-2" append-outer-icon="mdi-check" @click:append-outer="editName = false" filled v-model="screenToManage.name" hide-details dense></v-text-field>
                            </div>
                            <p v-else class="ma-0 ml-7 d-flex align-center justify-center text-center text-h5 primary--text font-weight-bold">
                                <span>{{screenToManage.name}} </span>
                                <v-btn @click="editName = true" small icon>
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </p>
                            <p class="justify-center ma-0 text-center d-flex font-italic text-body-2 font-weight-regular">{{$t("manageScreensNameHeaderType", { screenType: getScreenTypeName(screenToManage.templateType) })}}</p>
                            <CustomButton v-if="isLaunched" @click="isLaunched = false" xSmall>{{$t("setLaunch")}}</CustomButton>
                            <CustomButton v-else @click="isLaunched = true" outlined xSmall>{{$t("setLaunch")}}</CustomButton>
                        </div>

                    </div>
                    <v-divider width="50%" class="mx-5 mt-3 mb-5"></v-divider>
                    <template v-if="isNull(screenToManage)">
                        {{$t("screensEditScreensPlaceholder")}}
                        <v-btn class="mt-6" color="primary" @click="setNewScreen()" outlined icon large><v-icon>mdi-plus</v-icon></v-btn>
                    </template>
                    <template v-else>
                        <template v-if="isNewScreen">
                            <ManageScreen :screen="screenToManage" :key="`manage-screen-${screenToManage.id}`" :availableTemplateTypes="availableTemplateTypes" @screenUpdated1="screenUpdated1"></ManageScreen>
                        </template>
                        <!-- les screens -->
                        <template v-else>
                            <component :key="`screen-edit-${screenToManage.id}`" :availableScreens="screens" v-bind:is="componentsToUse" :screen="screenToManage" @screenUpdated2="screenUpdated2"></component>
                        </template>

                    </template>
                </div>
            </v-col>

            <v-col cols="3" class="screens-list">
                <div class="px-5 d-flex flex-column align-center">

                    <div class="mb-3 d-flex align-self-start align-center">
                        <p class="ma-0 text-h5 primary--text font-weight-bold">{{$t('screensListTitle')}}</p>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="ActionTerminalModalPreviewDialog" class="mx-2 primary--text">mdi-eye</v-icon>
                            </template>
                            <span>{{$t("previewBtn")}}</span>
                        </v-tooltip>
                    </div>
                    <div class="my-2 pa-3 rounded-t-lg d-flex align-center full-width screen-selector-btn" :class="{'active-screen': screenToManage && screen.id == screenToManage.id }" v-for="screen in screens" :key="screen.id">
                        <div class="d-flex flex-column">
                            <div class="d-flex align-center">
                                <p class="mb-0 darkGrey--text text-body-2 font-weight-bold">{{screen.name}}</p>
                                <v-icon class="mx-2 " v-if="screen.launchScreen" small>mdi-home</v-icon>
                            </div>
                            <p class="mb-0 darkGrey--text text-caption font-italic font-weight-regular">{{$t("screensCreationDate", {date: formatDateAndHourEnToDateLocale(screen.creationDate)})}}</p>
                        </div>

                        <v-spacer></v-spacer>
                        <v-btn @click="loadScreen(screen.id)" icon><v-icon>mdi-pencil-outline</v-icon></v-btn>
                        <v-btn @click="removeScreen(screen)" icon><v-icon>mdi-delete-outline</v-icon></v-btn>
                    </div>
                    <TerminalModalPreview v-if="showTerminalModalPreviewDialog" :screens="allscreens" :availableScreens="screens" :currentScreen="launchedScreen" @hide="showTerminalModalPreviewDialog = false" />
                </div>
            </v-col>
        </v-row>
    </div>
    <div class="screens" v-else>
        Veuillez sélectionner un scénario
    </div>
</template>

<script>
const ScreenService = require("@/services/ScreenService.js")
const ScenarioService = require("@/services/ScenarioService.js")
import ManageScreen from "@/components/SmartEngage/ManageScreen";
import YesNo from "@/components/SmartEngage/Screens/YesNo";
import Stars from "@/components/SmartEngage/Screens/Stars";
import Smiley from "@/components/SmartEngage/Screens/Smiley";
import DigitalEntry from "@/components/SmartEngage/Screens/DigitalEntry";
import QrCode from "@/components/SmartEngage/Screens/QrCode";
import QCM from "@/components/SmartEngage/Screens/QCM";
import TerminalModalPreview from "@/components/SmartEngage/TerminalModalPreview";
export default {
    name: "Screens",
    components: {QrCode, DigitalEntry, Smiley, Stars, YesNo, QCM, ManageScreen, TerminalModalPreview},
    data: (vm) => {
        return {
            showTerminalModalPreviewDialog: false,
            scenarioId: null,
            screens: [],
            selectedScreenIndex: null,
            manageStep: 1,
            screenToManage: null,
            isNewScreen: false,
            availableTemplateTypes: vm.$t('availableTemplateTypes'),
            editName: false,
            scenario: null,
            launchedScreen: null,
            allscreens: [],
            isLaunched: false
        }
    },
    created() {
        this.updatePageInfo(this.$route.meta)
        this.scenarioId = this.$route.query.scenarioId
        if(this.isNotNull(this.scenarioId)){
            this.getScenario()
            this.getScreens()
        }
    },

    methods:{
        ActionTerminalModalPreviewDialog(){
            this.screenLaunched()
            this.getAllscreens()
            this.showTerminalModalPreviewDialog = true
        },
        getAllscreens(){
            this.allscreens = [{id: -2, name: this.$t("noAction")}, ...this.screens]
        },
        screenLaunched(){
            this.launchedScreen = this.screens.find(screen => screen.launchScreen === true)
        },
        setNewScreen(){
            this.isNewScreen = true
            this.screenToManage = {
                id: -1,
                name: null,
                templateType: null,
                inputs: [],
            }
            this.manageStep = 1
            this.selectedScreenIndex = null
        },
        getScenario(){
            ScenarioService.getById(this.scenarioId).then(({status, data}) => {
                if(status === 200 && this.isNotNull(data)){
                    this.scenario = data
                }
            })
        },
        getScreens(){
            ScreenService.getAllScreens(this.scenarioId).then(({status, data}) => {
                if(status === 200 && data){
                    this.screens = data
                }
            })
        },
        loadScreen(id){
            //TODO : call ws pour charger un écran
            this.isNewScreen = false
            if(this.isNotNull(id)){
                this.screenToManage = this.screens.find(screen => screen.id == id)
                this.manageStep = 2
            }
            this.screenIsLaunched()
        },
        removeScreen(screen){
            if (window.confirm(this.$t("manageScreensDeleteConfirmation", {screenName: screen.name}))) {
                if(screen.id === -1){
                    this.screenToManage = null
                    this.getScreens()
                    this.$root.$emit('showAlert', {
                        "content":  this.$t('manageScreensDeletedSuccessfully'),
                        "color": "success",
                        "timeout": "5",
                        "closable": true
                    });
                }else{
                    ScreenService.remove(this.scenario.id, screen.id).then(({status, data}) => {
                        if(status === 200 && !data.error) {
                            this.getScreens()
                            this.$root.$emit('showAlert', {
                                "content":  this.$t('manageScreensDeletedSuccessfully'),
                                "color": "success",
                                "timeout": "5",
                                "closable": true
                            });
                        }else{
                            this.$root.$emit('showAlert', {
                                "content":  this.$t('manageScreensDeleteError'),
                                "color": "error",
                                "timeout": "5",
                                "closable": true
                            });
                        }
                    })
                }
            }
        },
        getScreenTypeName(typeCode){
            let foundType = this.availableTemplateTypes.find(type => type.code === typeCode)
            if(foundType){
                return foundType.name
            }
            return null
        },
        screenUpdated1(screen){
            let screenIndex = this.screens.findIndex(tempScreen => tempScreen.id === screen.id)
            if(screenIndex >= 0){
                this.screens[screenIndex] = screen
            }else{
                screenIndex = this.screens.push(screen) -1
            }
            this.screenToManage = this.screens[screenIndex]
            this.manageStep = 2
            this.isNewScreen = false
        },
        screenUpdated2(screen){
            let screenIndex = this.screens.findIndex(tempScreen => tempScreen.id === screen.id)
            if(screenIndex >= 0){
                this.screens[screenIndex] = screen
                if(screen.id === -1){
                    ScreenService.add(this.scenarioId, screen.name, screen.templateType, screen.inputs, this.screens.length === 1).then(({status, data}) => {
                        if(status === 200 && !data.error){
                            this.getScreens()
                            this.manageStep = 0
                            this.screenToManage = null
                            this.selectedScreenIndex = null
                            this.$root.$emit('showAlert', {
                                "content":  this.$t('manageScreensAddedSuccessfully'),
                                "color": "success",
                                "timeout": "5",
                                "closable": true
                            });
                        }else{
                            //TODO gestion erreur
                        }
                    })
                }else{
                    ScreenService.update(screen.id, this.screenToManage.name, screen.templateType, screen.inputs, this.isLaunched).then(({status, data}) => {
                        if(status === 200 && !data.error){
                            this.getScreens()
                            this.manageStep = 0
                            this.screenToManage = null
                            this.selectedScreenIndex = null
                            this.$root.$emit('showAlert', {
                                "content":  this.$t('manageScreensUpdatedSuccessfully'),
                                "color": "success",
                                "timeout": "5",
                                "closable": true
                            });
                        }else{
                            //TODO gestion erreur
                        }
                    })
                }
            }else{
                //TODO gestion erreur -> screen existe obligatoirement
            }
        },
        screenIsLaunched(){
            this.isLaunched = this.screenToManage.launchScreen
        }
    },
    computed: {
        componentsToUse() {
            if(this.screenToManage){
                let template = this.availableTemplateTypes.find(template => template.code == this.screenToManage.templateType)
                return template ? template.component : null
            }
            return null
        }
    },
    watch:{
        selectedScreenIndex(newVal, oldVal){
            if(this.isNotNull(oldVal) && this.isNull(newVal) && !this.isNewScreen){
                this.manageStep = 0
                this.screenToManage = null
            }
        }
    }
}
</script>

<style scoped>
.screen-selector-btn{
    border: 1px #DFDFDF solid;
    border-bottom: 3px var(--primary-color) solid;
}
.screen-creation-wrapper{
    box-shadow: 0px 0px 15px #9191911a;
}
.custom-input>>>.v-input__slot {
    margin: 0 !important;
}

.custom-input>>>.v-input__slot::before {
    border: none !important;
}

.custom-input>>>.v-input__slot::after {
    border: none !important;
}

.custom-input>>>.v-input__slot {
    border: none !important;
}
.active-screen{
    background: #1749c81f;
}
</style>
