<template>
    <div id="terminal-modal-preview">
        <v-row justify="center">

            <v-dialog v-model="showDialog" content-class="terminal-modal" height="350" width="380">
                <StepperTerminalModalPreview :availableScreens="availableScreens" :screenActiv="screenActiv" @screenActiv="screenActiv = $event"/>
                <v-card>


                    <div class="d-flex flex-column align-center terminalModalPreviewHeaderBackground">
                        <v-card-title>
                            <span class="size-title secondary--text text-h5 text-center font-weight-bold">{{ getValue('TITLE') }}</span>
                        </v-card-title>
                    </div>

                    <div :class="{'card-text-qr': screenActiv.templateType == 'QRCODE'}" class="align-center my-3 d-flex flex-column card-text">

                        <v-img v-if="getValue('LOGO')" :src="getValue('LOGO')" contain max-height="120" max-width="150"></v-img>
                        <v-icon v-else x-large>mdi-image-area</v-icon>

                        <v-card-text class="text-subtitle-1 text-h5 terminalModalPreview--text font-weight-bold">
                            <div class="mt-2 text-center">
                                {{ getValue('DESCRIPTION') }}
                            </div>
                        </v-card-text>

                        <!-- DIGITAL_ENTRY -->
                        <div v-if="screenActiv.templateType == 'DIGITAL_ENTRY'">
                            <v-list-item>
                                <div class="ma-auto">
                                    <v-text-field color="terminalModalPreview" dense label="0X XX XX XX XX" outlined readonly rounded></v-text-field>
                                </div>
                            </v-list-item>

                            <div class="d-flex">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="px-6" color="terminalModalPreview" dark rounded small width="100" @click="getScreen('CANCEL_DIGITAL_ENTRY_BUTTON')">{{ getValue('CANCEL_DIGITAL_ENTRY_BUTTON') }}</v-btn>
                                    </template>
                                    <span>{{ getNextActionName('CANCEL_DIGITAL_ENTRY_BUTTON') }}</span>
                                </v-tooltip>
                                <v-spacer></v-spacer>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="px-6" color="terminalModalPreview" dark rounded small width="100" @click="getScreen('SEND_DIGITAL_ENTRY_BUTTON')">{{ getValue('SEND_DIGITAL_ENTRY_BUTTON') }}</v-btn>
                                    </template>
                                    <span>{{ getNextActionName('SEND_DIGITAL_ENTRY_BUTTON') }}</span>
                                </v-tooltip>
                            </div>

                        </div>

                        <!-- QRCODE -->
                        <div v-if="screenActiv.templateType == 'QRCODE'" class="d-flex flex-column " style="width:300px;">
                            <v-img v-if="showImgQrcode" :src="require('@/assets/img/qrcode.png')" class="align-self-center" width="200"></v-img>
                            <div v-else id="shareModal" ref="shareModal" centered class="align-self-center" hide-footer>
                                <img :src="qrCodeImg" width="200"/>
                            </div>

                            <div class="text-end">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="mt-6 px-6" color=" terminalModalPreview" dark rounded small @click="getScreen('QR_CODE_BUTTON')">{{ getValue('QR_CODE_BUTTON') }}</v-btn>
                                    </template>
                                    <span>{{ getNextActionName('QR_CODE_BUTTON') }}</span>
                                </v-tooltip>
                            </div>
                        </div>

                        <!-- QCM -->
                        <div v-if="screenActiv.templateType == 'QCM'" class="d-flex flex-column " style="width:300px;">
                            <div centered class="qcm ml-2 align-self-start" style="height:225px; width: 100%;">
                                <template v-if="getValue('MAX_ANSWERS') > 1">
                                    <v-checkbox                                  
                                    v-for="(answers, index) in getValue('ANSWERS')" :key="index"
                                    v-model="checkedValue"
                                    :disabled="maxAnswerChecked(answers.value)"
                                    :value="answers.value"
                                    :label="answers.value"
                                    class="shrink mr-2 mt-0"
                                    ></v-checkbox>
                                </template>
                                <v-radio-group v-if="getValue('MAX_ANSWERS') == 1" v-model="radioGroup">
                                    <v-radio
                                        v-for="(answers, index) in getValue('ANSWERS')" :key="index"
                                        :label="answers.value"
                                        :value="answers.id"
                                        class="mb-5"
                                    ></v-radio>
                                </v-radio-group>
                            </div>
                            <p class="mt-1 font-italic text-subtitle-2 d-flex justify-end">{{$tc("maxAnswersLabel", getValue('MAX_ANSWERS'), { maxAnswerNumber: getValue('MAX_ANSWERS') }) }}</p>

                            <div class="mt-1 d-flex text-end">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="px-6" color="terminalModalPreview" dark rounded small width="100" @click="getScreen('CANCEL_QCM_BUTTON')">{{ getValue('CANCEL_QCM_BUTTON') }}</v-btn>
                                    </template>
                                    <span>{{ getNextActionName('CANCEL_QCM_BUTTON') }}</span>
                                </v-tooltip>
                                <v-spacer></v-spacer>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" class="px-6" color="terminalModalPreview" dark rounded small width="100" @click="getScreen('SEND_QCM_BUTTON')">{{ getValue('SEND_QCM_BUTTON') }}</v-btn>
                                    </template>
                                    <span>{{ getNextActionName("SEND_QCM_BUTTON") }}</span>
                                </v-tooltip>
                            </div>
                        </div>

                        <div v-if="screenActiv.templateType == 'SMILEY' || screenActiv.templateType == 'STARS' || screenActiv.templateType == 'YES_NO' " class="d-flex flex-column " style="height:130px;"></div>

                    </div>

                    <v-card-actions :class="{'no-size-actions': screenActiv.templateType == 'QRCODE' || screenActiv.templateType == 'QCM' }" class="size-actions">

                        <!-- YES_NO -->
                        <v-list-item v-if="screenActiv.templateType == 'YES_NO'">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }" color="secondary--text font-weight-bold ">
                                    <v-btn v-bind="attrs" v-on="on" color="red secondary--text font-weight-bold" dark fab rounded x-large @click="getScreen('NO_BUTTON')">{{ getValue('NO_BUTTON') }}</v-btn>
                                </template>
                                <span>{{ getNextActionName('NO_BUTTON') }}</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }" color="secondary--text font-weight-bold ">
                                    <v-btn v-bind="attrs" v-on="on" color="terminalModalPreview" dark fab rounded x-large @click="getScreen('YES_BUTTON')">{{ getValue('YES_BUTTON') }}</v-btn>
                                </template>
                                <span>{{ getNextActionName('YES_BUTTON') }}</span>
                            </v-tooltip>
                        </v-list-item>

                        <!-- SMILEY -->
                        <v-list-item v-else-if="screenActiv.templateType == 'SMILEY'">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" plain>
                                        <v-img :src="require('@/assets/img/angry.png')" class="row-pointer" max-width="80" @click="getScreen('ANGRY_BUTTON')"></v-img>
                                    </v-btn>
                                </template>
                                <span>{{ getNextActionName('ANGRY_BUTTON') }}</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" plain>
                                        <v-img :src="require('@/assets/img/neutral.png')" class="row-pointer" max-width="80" @click="getScreen('NEUTRAL_BUTTON')"></v-img>
                                    </v-btn>
                                </template>
                                <span>{{ getNextActionName('NEUTRAL_BUTTON') }}</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" plain>
                                        <v-img :src="require('@/assets/img/happy.png')" class="row-pointer" max-width="80" @click="getScreen('HAPPY_BUTTON')"></v-img>
                                    </v-btn>
                                </template>
                                <span>{{ getNextActionName('HAPPY_BUTTON') }}</span>
                            </v-tooltip>
                        </v-list-item>

                        <!-- STARS -->
                        <v-list-item v-else-if="screenActiv.templateType == 'STARS'">
                            <v-tooltip top v-for="item in [1, 2, 3, 4, 5]" :key="'STARS_BUTTON_' + item">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" plain class="ma-0 pa-0" :id="'STARS_BUTTON_' + item">
                                        <v-img max-width="50" max-height="50" src="@/assets/img/star.png" @click="getScreen('STARS_BUTTON_' + item)" />
                                    </v-btn>
                                </template>
                                <span>{{ getNextActionName('STARS_BUTTON_' + item) }}</span>
                            </v-tooltip>
                        </v-list-item>

                    </v-card-actions>

                </v-card>

            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import StepperTerminalModalPreview from "@/components/SmartEngage/StepperTerminalModalPreview";

var qrcodeGenerator = require('qrcode-generator')

export default {
    name: "TerminalModalPreview",
    props: ['currentScreen', 'screens', 'availableScreens'],
    components: {StepperTerminalModalPreview},
    data: (vm) => {
        return {
            showDialog: true,
            screenActiv: vm.currentScreen,
            dataQrCode: '',
            showImgQrcode: true,
            qrCodeImg: '',
            checkedValue: [],
            radioGroup: -1,
        }
    },
    created() {
        console.log(this.screenActiv);
        this.getDataQrCode()
        console.log(this.dataQrCode);
        var qr = qrcodeGenerator(0, 'L');
        qr.addData(this.dataQrCode);
        qr.make();
        this.qrCodeImg = qr.createDataURL(5, 0);
        this.$refs.shareModal;

    },
    computed: {
        getValue() {
            return (name) => {
                if(name == 'ANSWERS'){

                    let valueQcm = this.screenActiv.inputs.find(input => input.name === name)?.value
                    return JSON.parse(valueQcm)
                }
                return this.screenActiv.inputs.find(input => input.name === name)?.value
            }
        },
        maxAnswerChecked(){
            return (label) => {
                if(this.checkedValue.length >= this.getValue('MAX_ANSWERS')){
                    let index = this.checkedValue.find(value => value == label)
                    if (index) {
                        return false
                    }
                    return true
                }
                return false
            }
        }
    },
    methods: {
        getAction(name) {
            let index = this.screenActiv.inputs.findIndex(input => input.name === name)
            if (index !== -1) {
                if (this.isNotNull(this.screenActiv.inputs[index].action)) {
                    return parseInt(this.screenActiv.inputs[index].action)
                }
                return -2 //No Action
            }
            return -1 //Non défini
        },
        getScreen(actionName) {
            let actionScreen = this.getAction(actionName)
            if (actionScreen == -2 || actionScreen == -1) {
                this.showDialog = null
            } else {
                this.screenActiv = this.availableScreens.find(screen => screen.id == actionScreen)
            }
        },
        getNextActionName(actionName) {    
            let actionScreen = this.getAction(actionName)
            if (actionScreen == -2 || actionScreen == -1) {
                return this.screens.find(screen => screen.id == actionScreen).name
            } else if (actionScreen == this.currentScreen.id) {
                return this.availableScreens.find(screen => screen.id == actionScreen).templateType
            } else {
                return this.screens.find(screen => screen.id == actionScreen).templateType
            }
        },
        getDataQrCode() {
            if (this.screenActiv.templateType == 'QRCODE') {
                let urlQrcode = ''
                let QrCodeType = ''

                for (let i = 0; i < this.screenActiv.inputs.length; i++) {

                    if (this.screenActiv.inputs[i].name == 'QR_CODE_TYPE') {
                        QrCodeType = this.screenActiv.inputs[i].value
                        if (QrCodeType == 'DATA_RECEIPT_FROM') {
                            this.showImgQrcode = true
                        } else if (QrCodeType == 'CUSTOM_URL') {
                            urlQrcode = this.screenActiv.inputs.find(inputs => inputs.name == 'QR_CODE_URL').value
                            this.showImgQrcode = false
                            this.dataQrCode = `${urlQrcode}`
                        }
                    }

                }

            }

        },
    },
    watch: {
        showDialog() {
            if (!this.showDialog) {
                this.$emit("hide")
            }
        },
    }
}
</script>
<style>
#terminal-modal-preview {
    position: relative;
}

.size-title {
    word-break: break-word;
    height: 120px;
    padding: 30px;
    display: flex;
    align-items: center;
}

.terminal-modal .card-text {
    word-break: break-word;
    padding: 1rem;
}


.card-text-qr {
    height: 480px;
}

.size-actions {
    height: 150px;
}

.no-size-actions {
    height: 0px;
}

.v-btn--fab.v-size--x-large {
    height: 62px;
    width: 72px;
}

.v-btn--is-elevated.v-btn--fab {
    box-shadow: inherit;
}

.row-pointer {
    cursor: pointer;
}

.terminal-modal {
    position: relative !important;
    overflow: visible !important;
}

</style>
<style scoped>
.qcm{
    overflow-y: auto;
    overflow-x: hidden;
}
.qcm >>> .v-input__control{
    height: 100% !important;
}
.qcm >>> .v-input__slot{
    height: 100% !important;
}
.qcm >>> .v-input--radio-group__input{
    height: 100% !important;
}
.shrink{
    height: initial !important;
}
</style>