var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"terminal-modal-preview"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"content-class":"terminal-modal","height":"350","width":"380"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('StepperTerminalModalPreview',{attrs:{"availableScreens":_vm.availableScreens,"screenActiv":_vm.screenActiv},on:{"screenActiv":function($event){_vm.screenActiv = $event}}}),_c('v-card',[_c('div',{staticClass:"d-flex flex-column align-center terminalModalPreviewHeaderBackground"},[_c('v-card-title',[_c('span',{staticClass:"size-title secondary--text text-h5 text-center font-weight-bold"},[_vm._v(_vm._s(_vm.getValue('TITLE')))])])],1),_c('div',{staticClass:"align-center my-3 d-flex flex-column card-text",class:{'card-text-qr': _vm.screenActiv.templateType == 'QRCODE'}},[(_vm.getValue('LOGO'))?_c('v-img',{attrs:{"src":_vm.getValue('LOGO'),"contain":"","max-height":"120","max-width":"150"}}):_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-image-area")]),_c('v-card-text',{staticClass:"text-subtitle-1 text-h5 terminalModalPreview--text font-weight-bold"},[_c('div',{staticClass:"mt-2 text-center"},[_vm._v(" "+_vm._s(_vm.getValue('DESCRIPTION'))+" ")])]),(_vm.screenActiv.templateType == 'DIGITAL_ENTRY')?_c('div',[_c('v-list-item',[_c('div',{staticClass:"ma-auto"},[_c('v-text-field',{attrs:{"color":"terminalModalPreview","dense":"","label":"0X XX XX XX XX","outlined":"","readonly":"","rounded":""}})],1)]),_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-6",attrs:{"color":"terminalModalPreview","dark":"","rounded":"","small":"","width":"100"},on:{"click":function($event){return _vm.getScreen('CANCEL_DIGITAL_ENTRY_BUTTON')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.getValue('CANCEL_DIGITAL_ENTRY_BUTTON')))])]}}],null,false,2102984749)},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('CANCEL_DIGITAL_ENTRY_BUTTON')))])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-6",attrs:{"color":"terminalModalPreview","dark":"","rounded":"","small":"","width":"100"},on:{"click":function($event){return _vm.getScreen('SEND_DIGITAL_ENTRY_BUTTON')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.getValue('SEND_DIGITAL_ENTRY_BUTTON')))])]}}],null,false,1329801389)},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('SEND_DIGITAL_ENTRY_BUTTON')))])])],1)],1):_vm._e(),(_vm.screenActiv.templateType == 'QRCODE')?_c('div',{staticClass:"d-flex flex-column ",staticStyle:{"width":"300px"}},[(_vm.showImgQrcode)?_c('v-img',{staticClass:"align-self-center",attrs:{"src":require('@/assets/img/qrcode.png'),"width":"200"}}):_c('div',{ref:"shareModal",staticClass:"align-self-center",attrs:{"id":"shareModal","centered":"","hide-footer":""}},[_c('img',{attrs:{"src":_vm.qrCodeImg,"width":"200"}})]),_c('div',{staticClass:"text-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-6 px-6",attrs:{"color":" terminalModalPreview","dark":"","rounded":"","small":""},on:{"click":function($event){return _vm.getScreen('QR_CODE_BUTTON')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.getValue('QR_CODE_BUTTON')))])]}}],null,false,1730346126)},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('QR_CODE_BUTTON')))])])],1)],1):_vm._e(),(_vm.screenActiv.templateType == 'QCM')?_c('div',{staticClass:"d-flex flex-column ",staticStyle:{"width":"300px"}},[_c('div',{staticClass:"qcm ml-2 align-self-start",staticStyle:{"height":"225px","width":"100%"},attrs:{"centered":""}},[(_vm.getValue('MAX_ANSWERS') > 1)?_vm._l((_vm.getValue('ANSWERS')),function(answers,index){return _c('v-checkbox',{key:index,staticClass:"shrink mr-2 mt-0",attrs:{"disabled":_vm.maxAnswerChecked(answers.value),"value":answers.value,"label":answers.value},model:{value:(_vm.checkedValue),callback:function ($$v) {_vm.checkedValue=$$v},expression:"checkedValue"}})}):_vm._e(),(_vm.getValue('MAX_ANSWERS') == 1)?_c('v-radio-group',{model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},_vm._l((_vm.getValue('ANSWERS')),function(answers,index){return _c('v-radio',{key:index,staticClass:"mb-5",attrs:{"label":answers.value,"value":answers.id}})}),1):_vm._e()],2),_c('p',{staticClass:"mt-1 font-italic text-subtitle-2 d-flex justify-end"},[_vm._v(_vm._s(_vm.$tc("maxAnswersLabel", _vm.getValue('MAX_ANSWERS'), { maxAnswerNumber: _vm.getValue('MAX_ANSWERS') })))]),_c('div',{staticClass:"mt-1 d-flex text-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-6",attrs:{"color":"terminalModalPreview","dark":"","rounded":"","small":"","width":"100"},on:{"click":function($event){return _vm.getScreen('CANCEL_QCM_BUTTON')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.getValue('CANCEL_QCM_BUTTON')))])]}}],null,false,2632157997)},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('CANCEL_QCM_BUTTON')))])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-6",attrs:{"color":"terminalModalPreview","dark":"","rounded":"","small":"","width":"100"},on:{"click":function($event){return _vm.getScreen('SEND_QCM_BUTTON')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.getValue('SEND_QCM_BUTTON')))])]}}],null,false,2281483309)},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName("SEND_QCM_BUTTON")))])])],1)]):_vm._e(),(_vm.screenActiv.templateType == 'SMILEY' || _vm.screenActiv.templateType == 'STARS' || _vm.screenActiv.templateType == 'YES_NO' )?_c('div',{staticClass:"d-flex flex-column ",staticStyle:{"height":"130px"}}):_vm._e()],1),_c('v-card-actions',{staticClass:"size-actions",class:{'no-size-actions': _vm.screenActiv.templateType == 'QRCODE' || _vm.screenActiv.templateType == 'QCM' }},[(_vm.screenActiv.templateType == 'YES_NO')?_c('v-list-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red secondary--text font-weight-bold","dark":"","fab":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.getScreen('NO_BUTTON')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.getValue('NO_BUTTON')))])]}}],null,false,2897542204)},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('NO_BUTTON')))])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"terminalModalPreview","dark":"","fab":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.getScreen('YES_BUTTON')}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.getValue('YES_BUTTON')))])]}}],null,false,1070402875)},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('YES_BUTTON')))])])],1):(_vm.screenActiv.templateType == 'SMILEY')?_c('v-list-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"row-pointer",attrs:{"src":require('@/assets/img/angry.png'),"max-width":"80"},on:{"click":function($event){return _vm.getScreen('ANGRY_BUTTON')}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('ANGRY_BUTTON')))])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"row-pointer",attrs:{"src":require('@/assets/img/neutral.png'),"max-width":"80"},on:{"click":function($event){return _vm.getScreen('NEUTRAL_BUTTON')}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('NEUTRAL_BUTTON')))])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"row-pointer",attrs:{"src":require('@/assets/img/happy.png'),"max-width":"80"},on:{"click":function($event){return _vm.getScreen('HAPPY_BUTTON')}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('HAPPY_BUTTON')))])])],1):(_vm.screenActiv.templateType == 'STARS')?_c('v-list-item',_vm._l(([1, 2, 3, 4, 5]),function(item){return _c('v-tooltip',{key:'STARS_BUTTON_' + item,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 pa-0",attrs:{"plain":"","id":'STARS_BUTTON_' + item}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"max-width":"50","max-height":"50","src":require("@/assets/img/star.png")},on:{"click":function($event){return _vm.getScreen('STARS_BUTTON_' + item)}}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getNextActionName('STARS_BUTTON_' + item)))])])}),1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }