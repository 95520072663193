<template>
    <div id="stepperTerminalModalPreview" class="stepper d-xl-flex justify-center">
        <div v-for="screen in availableScreens" :key="screen.id" class="mx-2 my-2">

            <div v-if="isNotNull(screen.id)" :class="{'opacity': screen.id != screenActiv.id}">
                <v-avatar v-if="screen.launchScreen" class="home-icon" color="white" size="16">
                    <v-icon small>mdi-home</v-icon>
                </v-avatar>
                <v-img :class="{'box-shadow': screen.id == screenActiv.id}" :src="selectedScreen(screen.templateType)" class="row-pointer" max-width="40" @click="changeScreen(screen.id)"></v-img>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "StepperTerminalModalPreview",
    props: ['availableScreens', 'screenActiv'],
    data: () => {
        return {
            show: false,
            tesxt: ''
        }
    },
    created() {
        console.log(this.screenActiv)

        setTimeout(() => {
            this.show = true
        }, 300)
    },
    methods: {
        changeScreen(screenId) {
            let screenChanged = this.availableScreens.find(screen => screen.id == screenId)
            this.$emit('screenActiv', screenChanged)
        },
        selectedScreen(screenTemplate) {
            switch (screenTemplate) {
                case "YES_NO":
                    return require('@/assets/img/screens/yesno.png')
                case "QRCODE":
                    return require('@/assets/img/screens/qrcode.png')
                case "SMILEY":
                    return require('@/assets/img/screens/smiley.png')
                case "STARS":
                    return require('@/assets/img/screens/stars.png')
                case "DIGITAL_ENTRY":
                    return require('@/assets/img/screens/digital.png')
                case "QCM":
                    return require('@/assets/img/screens/qcmCheckBox.png')

            }
        }
    },
}
</script>

<style scoped>
.stepper {
    position: absolute;
    z-index: 1000;
    left: -56px;

}

.v-dialog > * {
    width: inherit;
}

.opacity {
    opacity: 0.55;
}

.box-shadow {
    box-shadow: 0px 1px 6px 3px #005A8C
}

.row-pointer {
    cursor: pointer;
}

.home-icon {
    position: absolute;
    z-index: 1002;
    margin-left: -10px;
    margin-top: -6px;
}


@media screen and (min-width: 1904px) {
    .stepper {
        top: -84px;
        left: inherit;
    }

    .home-icon {
        margin-left: -10px;
        margin-top: -6px;
    }
}
</style>
