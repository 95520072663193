<template>
    <div class="manage-screen-1">
        <v-form ref="manageScreenFrom" v-model="valid" class="my-6" lazy-validation>

            <div class="mb-5 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("manageScreensNameLabel") }}</p>
                <v-text-field v-model="currentScreen.name" class="custom-input rounded-lg font-weight-bold text-body-2" dense filled hide-details></v-text-field>
            </div>

            <div class="my-5 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("manageScreensTypeLabel") }}</p>
                <v-select
                    v-model="fullType"
                    :items="availableTemplateTypes"
                    class="custom-input rounded-lg font-weight-bold text-body-2"
                    dense
                    filled
                    hide-details
                    item-text="name"
                    required
                    return-object
                    @change="changeTemplateType($event)"
                ></v-select>
            </div>
        </v-form>

        <div class="d-flex align-center full-width">
            <v-spacer></v-spacer>
            <CustomButton @click="save">{{ $t("manageScreensSaveBtnLabel") }}</CustomButton>
        </div>

    </div>
</template>

<script>
export default {
    name: "ManageScreen",
    props: ['screen', 'availableTemplateTypes'],
    data: () => {
        return {
            currentScreen: {
                id: -1,
                name: null,
                templateType: null,
                text: null,
                image: null,
            },
            valid: true,
            fullType: null
        }
    },
    created() {
        if (this.isNotNull(this.screen)) {
            this.currentScreen = JSON.parse(JSON.stringify(this.screen))
            this.fullType = this.availableTemplateTypes.find(templateType => templateType.code === this.currentScreen.templateType)
        }
    },
    methods: {
        save() {
            if (this.$refs.manageScreenFrom.validate()) {
                //TODO : call WS update du nom et type d'écran
                this.$emit('screenUpdated1', this.currentScreen)
            }
        },
        changeTemplateType(templateType) {
            this.currentScreen.templateType = templateType.code
        }
    }
}
</script>


<style scoped>
.custom-input >>> .v-input__slot {
    margin: 0 !important;
}

.custom-input >>> .v-input__slot::before {
    border: none !important;
}

.custom-input >>> .v-input__slot::after {
    border: none !important;
}

.custom-input >>> .v-input__slot {
    border: none !important;
}
</style>
