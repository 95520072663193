import {isNull} from "@/assets/js/Utils";

const axios = require('axios');
import {config} from '@/assets/js/Utils.js';

export function add(scenarioId, name, templateType, inputs, launchScreen){
    let path = "/screen/add"

    for(let input of inputs){
        if(isNull(input.action)){
            delete input.action
        }
    }

    let params = new URLSearchParams();
    params.append("scenarioId", scenarioId);
    params.append("name", name);
    params.append("templateType", templateType);
    params.append("isLaunchScreen", launchScreen);
    params.append("inputs", JSON.stringify(inputs));

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}

export function getAllScreens(id){
    let path = "/screen/get/all?scenarioId=" + id

    return axios.get(config.SMART_ENGAGE_WS_URL + path)
}

export function update(screenId, name, templateType, inputs, launchScreen){
    let path = "/screen/update/by/id"

    for(let input of inputs){
        if(isNull(input.action)){
            delete input.action
        }
    }

    let params = new URLSearchParams();
    params.append("screenId", screenId);
    params.append("name", name);
    params.append("templateType", templateType);
    params.append("isLaunchScreen", launchScreen);
    params.append("inputs", JSON.stringify(inputs));

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}


export function remove(scenarioId, screenId){
    let path = "/screen/remove/by/id"

    let params = new URLSearchParams();
    params.append("scenarioId", scenarioId);
    params.append("screenId", screenId);

    return axios.post(config.SMART_ENGAGE_WS_URL + path, params)
}
