<template>
    <div v-if="screen" class="align-center screen-stars">
        <v-form
            ref="starsForm"
            v-model="valid"
            lazy-validation
        >
            <div class="mb-2 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("starsTitleLabel") }}</p>
                <v-text-field
                    :rules="[rules.notEmpty]"
                    :value="getValue('TITLE')"
                    class="custom-input rounded-lg font-weight-bold text-body-2"
                    dense
                    filled
                    required
                    type="text"
                    @change="setValue('TITLE', $event)"></v-text-field>
            </div>

            <div class="mb-2 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("starsContentLabel") }}</p>
                <v-textarea
                    :rules="[rules.notEmpty]"
                    :value="getValue('DESCRIPTION')"
                    class="custom-input rounded-lg font-weight-bold text-body-2"
                    dense
                    filled
                    full-width
                    required
                    type="text"
                    @change="setValue('DESCRIPTION', $event)"></v-textarea>
            </div>


            <div class="mb-2 d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{ $t("starsUrlLabel") }}</p>
                <v-avatar class="my-3 align-self-center" color="lightGrey" size="150">
                    <template v-if="getValue('LOGO')">
                        <v-img :src="getValue('LOGO')" contain max-height="120" max-width="120"></v-img>
                    </template>
                    <template v-else>
                        <v-icon x-large>mdi-image-area</v-icon>
                    </template>
                </v-avatar>
                <div class="d-flex align-center">
                    <v-text-field
                        :rules="[rules.notEmpty]"
                        :value="getValue('LOGO')"
                        class="custom-input rounded-lg font-weight-bold text-body-2"
                        dense
                        filled
                        required
                        type="url"
                        @change="setValue('LOGO', $event)"></v-text-field>
                </div>
            </div>

            <div class="mb-2 d-flex flex-column input-group" v-for="n in 5" :key="`stars-${n}`">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">
                  {{ $t("starsActionLabel") }}
                  <v-icon class="metrics-icon" v-for="s in n" :key="`icon-stars-${s}`" color="yellow">mdi-star</v-icon>

                </p>
                <v-select
                    :items="screens"
                    :rules="[rules.notEmpty]"
                    :value="getAction('STARS_BUTTON_'+n)"
                    class="custom-input rounded-lg font-weight-bold text-body-2"
                    dense
                    filled
                    item-text="name"
                    item-value="id"
                    required
                    @change="setAction('STARS_BUTTON_'+n, $event)"
                ></v-select>
            </div>

        </v-form>

        <div class="d-flex align-center full-width">
            <CustomButton outlined @click="showTerminalModalPreviewDialog = true">{{ $t('previewBtn') }}</CustomButton>
            <v-spacer></v-spacer>
            <CustomButton @click="save">{{ $t("saveBtn") }}</CustomButton>
        </div>
        <TerminalModalPreview v-if="showTerminalModalPreviewDialog" :availableScreens="availableScreens" :currentScreen="currentScreen" :screens="screens" @hide="showTerminalModalPreviewDialog = false"/>
    </div>
</template>

<script>
import TerminalModalPreview from "@/components/SmartEngage/TerminalModalPreview";

export default {
    name: "Stars",
    props: ['screen', 'availableScreens'],
    components: {TerminalModalPreview},
    data: (vm) => {
        return {
            showTerminalModalPreviewDialog: false,
            currentScreen: {
                id: -1,
                name: null,
                templateType: null,
                inputs: [],
                launchScreen: false,
            },
            valid: true,
            rules: {
                notEmpty: value => vm.isNotNull(value) ? true : vm.$t('emptyFieldError')
            },
        }
    },
    computed: {
        screens() {
            let screens = [{id: -2, name: this.$t("noAction")}, ...this.availableScreens]
            return screens.filter(screen => screen.id !== this.currentScreen.id)
        }
    },
    created() {
        if (this.isNotNull(this.screen)) {
            this.currentScreen = JSON.parse(JSON.stringify(this.screen))
        }
    },
    methods: {
        save() {
            if (this.$refs.starsForm.validate()) {
                this.$emit('screenUpdated2', this.currentScreen)
            }
        },
        getValue(name) {
            let index = this.currentScreen.inputs.findIndex(input => input.name === name)
            if (index !== -1) {
                return this.currentScreen.inputs[index].value
            }
            return null
        },
        getAction(name) {
            let index = this.currentScreen.inputs.findIndex(input => input.name === name)
            if (index !== -1) {
                if (this.isNotNull(this.currentScreen.inputs[index].action)) {
                    return parseInt(this.currentScreen.inputs[index].action)
                }
                return -2 //No Action
            }
            return -1 //Non défini
        },
        setValue(name, value) {
            let index = this.currentScreen.inputs.findIndex(input => input.name === name)
            if (index !== -1) {
                this.currentScreen.inputs[index].value = value
            } else {
                switch (name) {
                    case "TITLE":
                        this.currentScreen.inputs.push({
                            name: "TITLE",
                            value: value,
                            type: "TEXT",
                        });
                        break;
                    case "DESCRIPTION":
                        this.currentScreen.inputs.push({
                            name: "DESCRIPTION",
                            value: value,
                            type: "TEXT",
                        });
                        break;
                    case "LOGO":
                        this.currentScreen.inputs.push({
                            name: "LOGO",
                            value: value,
                            type: "IMAGE",
                        });
                        break;
                }
            }
        },
        setAction(name, actionScreenId) {
            let index = this.currentScreen.inputs.findIndex(input => input.name === name)
            if (index === -1) {
                this.currentScreen.inputs.push({
                    name: name,
                    value: "STARS",
                    type: "BUTTON"
                })
                index = this.currentScreen.inputs.length - 1
            }

            if (actionScreenId >= 0) {
                this.currentScreen.inputs[index].action = actionScreenId
            } else {
                delete this.currentScreen.inputs[index].action
            }
        }
    }
}
</script>

<style scoped>
.screen-stars {
    min-width: 400px;
}

.custom-input >>> .v-input__slot {
    margin: 0 !important;
}

.custom-input >>> .v-input__slot::before {
    border: none !important;
}

.custom-input >>> .v-input__slot::after {
    border: none !important;
}

.custom-input >>> .v-input__slot {
    border: none !important;
}
</style>
